<template>
	<div class="bg_transp">
		<h3>Admin</h3>

	</div>
</template>

<script>

import axios from "axios";

export default {
	name: "Bier",

	data: () => ({
		items: [],        
		headers: [],
	}),

	created() {
       
	},
    methods: {
		sendEmail() {
            //var url_host = window.location.hostname; //console.log("url_host = "+url_host); // geen poortnummer..  
            //var url_path = window.location.pathname; //console.log("url_path = "+url_path); 
            console.log("url = ", this.rootURL+'?action=bSendMail&stdnr='+ this.stdnr)
            // https://stage.stegion.nl/api_mail/api.php?action=bSendMail&stdnr=97070695
            let url_mail = "https://stage.stegion.nl/api_mail/api.php?action=bSendMail&stdnr=97070695";
            axios({
                method: 'get',
                url: url_mail, //this.rootURL+'?action=bSendMail&stdnr='+ this.stdnr,
            })
                .then(function (response) {
                    console.log("Ingelogd oke. Response: ",response);
                    console.log('response.data.sMessage='+response.data.sMessage);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
	},
};
</script>

<style scoped>
    .bg_transp {
        background-color:rgba(255,255,255, .8);
        padding:1em;
        border-radius: 4px;

    }
</style>
