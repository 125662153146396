import { render, staticRenderFns } from "./Adm.vue?vue&type=template&id=f5f9a6e4&scoped=true&"
import script from "./Adm.vue?vue&type=script&lang=js&"
export * from "./Adm.vue?vue&type=script&lang=js&"
import style0 from "./Adm.vue?vue&type=style&index=0&id=f5f9a6e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5f9a6e4",
  null
  
)

export default component.exports